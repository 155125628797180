@use '@angular/material' as mat;

@import 'variables';

@mixin split-button-theme($theme) {
  $config: mat.get-color-config($theme);
  $is-dark: map-get($config, is-dark);

  .split-button {
    display: flex;
    justify-content: space-between;

    .mat-button-wrapper {
      justify-content: flex-start;
    }

    // main action
    .left {
      width: 100%;
      margin-right: 0 !important;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;

      &.mat-stroked-button {
        border-right-width: 0;
      }

      &.mat-raised-button {
        box-shadow: none;
      }
    }

    // dropdown arrow
    .right {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 44px;
      min-width: auto;
      margin-left: 0 !important;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;

      &.mat-raised-button {
        box-shadow: none;
      }

      mat-icon {
        margin: 0;
      }
    }

    // :has() isn't supported in Firefox, but it's not a big deal,
    // in that case split button will just lack the box-shadow, everything else will be the same
    @supports selector(:has(*)) {
      &:has(.mat-raised-button) {
        border-radius: 4px;
        transition: box-shadow 280ms;
        box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%),
          0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);

        &:active {
          box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 20%),
            0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%);
        }
      }

      &:has(.mat-raised-button[disabled]) {
        box-shadow: none !important;
      }
    }
  }
}

@mixin slide-toggle-group-theme($theme) {
  $config: mat.get-color-config($theme);
  $is-dark: map-get($config, is-dark);

  .mat-slide-toggle-group {
    display: flex;
    align-items: center;
    justify-content: space-between;

    > label {
      font-weight: var(--fw-normal);
    }
  }
}

@mixin radio-bg-button-theme($theme) {
  $config: mat.get-color-config($theme);
  $is-dark: map-get($config, is-dark);

  .radio-bg-button {
    &:hover {
      color: var(--c-white);

      .radio-bg-label {
        background-color: var(--c-gray-3);
      }
    }

    .mat-radio-label {
      margin-bottom: 0;
    }

    .mat-radio-label-content {
      flex: 1;
    }

    &.mat-radio-disabled {
      pointer-events: none;

      .radio-bg-preview {
        filter: grayscale(100%);
      }
    }

    .radio-bg-label {
      display: flex;
      align-items: center;
      flex: 1;
      padding: var(--s-1);
      gap: var(--s-3);
      font-weight: var(--fw-bold);
      white-space: normal;
      border-radius: var(--s-1);
    }

    .radio-bg-preview {
      width: 60px;
      height: 60px;
      border-radius: var(--s-1);
      object-fit: cover;
      background-color: var(--c-border);
    }
  }
}
