html,
body {
  position: relative;
  background: white;
  width: 100%;
  height: 100%;
  overflow: hidden;
  user-select: none;

  @media print {
    display: none;
  }

  // elements
  button {
    margin: 0;
    padding: 0;
    outline: 0;
    border: 0;
    cursor: pointer;
    background: none;
  }

  textarea {
    outline: none;
    border: 0;
    resize: none;
    overflow: auto;
  }

  a:focus,
  button::-moz-focus-inner {
    outline: none;
  }

  svg {
    pointer-events: none;
  }
}
