// This file contains minor overrides of the Material elements
// to better suit our Design System, supporting all themes without duplicating code
// More information about theming components can be found here:
// https://v13.material.angular.io/guide/theming-your-components

@use 'sass:map';
@use '@angular/material' as mat;

@import 'variables';

// Menu
@mixin tweak-menu-color($theme) {
  $config: mat.get-color-config($theme);
  $is-dark: map-get($config, is-dark);

  .mat-menu-item:hover:not([disabled]),
  .mat-menu-item.cdk-program-focused:not([disabled]),
  .mat-menu-item.cdk-keyboard-focused:not([disabled]),
  .mat-menu-item-highlighted:not([disabled]) {
    @if $is-dark {
      background: $gray-5;
    } @else {
      background: $gray-7;
      color: $gray-2;
    }
  }
}

@mixin tweak-expansion-theme($theme) {
  .mat-expansion-panel {
    &.documentation-appointment-item {
      background: var(--c-gray-1) !important;
      border-color: var(--c-gray-4);
      border-style: solid;
      border-width: 1px 0px 1px 0px;
      border-radius: 0px;
      .mat-expansion-panel-header {
        font-size: 14px;
        padding: var(--s-3);
        .mat-icon {
          line-height: 1;
          font-size: 36px;
          height: 36px;
          width: 36px;
        }
        &.mat-expanded {
          height: 48px;
        }
      }
      .mat-expansion-panel-body {
        padding: 0px;
      }
    }
  }
}

@mixin tweak-menu-theme($theme) {
  @include tweak-menu-color($theme);

  .mat-menu-panel {
    &.narrow-menu {
      min-width: auto;
    }

    // compact-menu-panel is currently used for the sort menu in queues list
    &.compact-menu-panel {
      margin-left: 50px;
    }

    &.pl-menu-panel {
      width: 216px !important;
      border-radius: 0px;
      .mat-menu-content {
        padding: 0px !important;
        .pl-menu-content {
          background-color: var(--c-gray-1);
          border-color: var(--c-gray-6);
          border-style: solid;
          border-width: 0px 1px 1px 1px;
          border-radius: 0px 0px 4px 4px;
          .selected-menu-item {
            background-color: var(--c-gray-3);
          }
          .custom-select-options-container {
            padding: 10px;
            color: var(--c-gray-6);
            max-height: 200px;
            overflow-y: auto;
            .mat-list-item {
              height: 36px;
              overflow: hidden;
              .mat-list-item-content {
                padding: 0px;
              }
            }
            .mat-checkbox-checked.custom-check.mat-accent
              .mat-checkbox-background {
              background-color: var(--c-blue-6) !important;
            }
            mat-checkbox.custom-check {
              .mat-checkbox-checkmark-path {
                stroke: black !important;
              }
              .mat-checkbox-inner-container {
                margin: 0px 8px 0px 0px;
              }
              .mat-checkbox-layout {
                align-items: flex-start;
              }
              .mat-checkbox-label {
                font-weight: var(--fw-normal);
                color: var(--c-gray-6) !important;
              }
              .mat-checkbox-frame {
                border-color: var(--c-gray-5) !important;
              }
              .mat-checkbox-label {
                font-size: var(--fs-2) !important;
                text-wrap: wrap;
                line-height: 18px;
              }
            }
          }
          .button-container {
            display: flex;
            flex-direction: column;
            border-top: 1px solid var(--c-gray-6);
            padding: 6px;
            gap: 6px;
            button {
              display: flex;
              align-items: center;
              justify-content: space-around;
              color: var(--c-gray-2);
              width: 100%;
              border-radius: 4px;
            }
            .open-button {
              color: var(--blue-blue-5, #82c1ff);
            }
            .done-button {
              background: var(--blue-blue-5, #82c1ff);
            }
          }
        }
      }
    }

    a.mat-menu-item {
      text-decoration: none;
    }
  }
}

@mixin tweak-button-toggle-color($theme) {
  $config: mat.get-color-config($theme);
  $is-dark: map-get($config, is-dark);

  @if $is-dark {
    .mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
    .mat-button-toggle-group-appearance-standard {
      border-color: $gray-6;
    }

    .mat-button-toggle-group-appearance-standard {
      .mat-button-toggle + .mat-button-toggle {
        border-left-color: $gray-6;
      }

      &.mat-button-toggle-disabled {
        .mat-button-toggle + .mat-button-toggle {
          border-color: $gray-4;
        }
      }
    }

    .mat-button-toggle-appearance-standard {
      background-color: transparent;
      color: $gray-6;

      &.mat-button-toggle-disabled {
        background-color: transparent;
        border-color: $gray-4;
      }

      &.mat-button-toggle-checked {
        background-color: $blue-5;
        color: $gray-3;
      }
    }
  }
}

@mixin tweak-button-toggle-theme($theme) {
  @include tweak-button-toggle-color($theme);

  .mat-button-toggle-group {
    .mat-button-toggle {
      flex: 1;
    }
  }

  .mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
    line-height: 36px;
    font-weight: $fw-bold;
    padding: 0 var(--s-2) !important;
  }
}

// Form Field
@mixin tweak-form-field-color($theme) {
  $config: mat.get-color-config($theme);
  $is-dark: map-get($config, is-dark);

  // Updates border, label and hint colors instead of relying on default rgba values
  .mat-form-field-appearance-outline .mat-form-field-outline,
  .mat-form-field-appearance-outline .mat-hint,
  .mat-form-field-appearance-outline .mat-form-field-label {
    color: if($is-dark, $gray-6, $gray-5);
  }
}

@mixin tweak-form-field-theme($theme) {
  @include tweak-form-field-color($theme);

  // Aligns prefix and suffix with the input text inside
  // form field with outline style
  .mat-form-field-appearance-outline .mat-form-field-prefix,
  .mat-form-field-appearance-outline .mat-form-field-suffix {
    > span {
      position: relative;
      top: -0.25em;
    }
  }
}

// Tab Group
@mixin tweak-tab-group-color($theme) {
  $color-config: mat.get-color-config($theme);
  $is-dark: map.get($color-config, is-dark);
  $primary-palette: map.get($color-config, 'primary');
  $accent-palette: map.get($color-config, 'accent');

  .mat-tab-labels .mat-tab-label {
    color: $gray-6;
    opacity: 1;
  }

  .mat-tab-group.mat-primary {
    .mat-tab-label.mat-tab-label-active {
      color: mat.get-color-from-palette($primary-palette, 500);
    }
  }

  .mat-tab-group.mat-accent {
    .mat-tab-label.mat-tab-label-active {
      color: mat.get-color-from-palette($accent-palette, 500);
    }
  }

  .mat-tab-header {
    border-bottom-color: if($is-dark, $gray-3, $gray-6);
  }

  .mat-tab-body {
    background: if($is-dark, $gray-2, transparent);
  }
}

@mixin tweak-tab-group-theme($theme) {
  @include tweak-tab-group-color($theme);

  .mat-tab-group {
    .mat-tab-label {
      min-width: 0;

      &.mat-tab-label-active {
        opacity: 1;
      }
    }

    .mat-ink-bar {
      border-radius: 4px 4px 0 0;
      height: 4px;
    }

    .mat-tab-body-wrapper {
      flex: 1;
    }
  }

  .mat-tab-group.is-compact {
    .mat-tab-labels {
      padding: 0 var(--s-2);
      gap: var(--s-2);
    }
    &.three-tabs {
      .mat-tab-labels {
        padding: 0 var(--s-1);
      }
    }

    .mat-tab-label {
      padding: 0 var(--s-3);
      font-size: var(--fs-2);
      font-weight: var(--fw-medium);
    }

    .mat-tab-label-content {
      margin-top: var(--s-3);
    }

    .mat-ink-bar {
      border-radius: 3px 3px 0 0;
      height: 3px;
    }
  }
}

// Buttons
@mixin tweak-button-color($theme) {
  $config: mat.get-color-config($theme);

  .mat-stroked-button:not(.mat-button-disabled) {
    border-color: currentColor;
  }

  // Adds support for new color: 'success'
  .mat-success {
    &.mat-flat-button,
    &.mat-raised-button,
    &.mat-fab,
    &.mat-mini-fab {
      background-color: var(--c-success);
      color: $white;
    }

    &.mat-button,
    &.mat-icon-button,
    &.mat-stroked-button {
      color: var(--c-success);

      .mat-button-focus-overlay {
        background-color: var(--c-success);
      }
    }
  }
}

@mixin tweak-button-theme($theme) {
  @include tweak-button-color($theme);

  .mat-button,
  .mat-flat-button,
  .mat-stroked-button,
  .mat-raised-button {
    min-height: 44px;

    &.is-rounded {
      border-radius: 44px;
    }

    &.is-small {
      min-height: 28px;
      font-size: var(--fs-2);
      line-height: 1em;
    }
  }

  .mat-button-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }

  .pl-menu-button {
    &.mat-button {
      padding: 0 var(--s-3) 0 var(--s-4);
    }
    .mat-button-wrapper {
      width: 100%;
      justify-content: space-between;
    }
  }
}

// Slide Toggle
@mixin tweak-slide-toggle-color($theme) {
  $color-config: mat.get-color-config($theme);
  $is-dark: map.get($color-config, is-dark);
  $primary-palette: map.get($color-config, 'primary');
  $foreground: map.get($color-config, foreground);

  .mat-slide-toggle-thumb {
    background-color: if($is-dark, $gray-5, $gray-5);
  }

  .mat-slide-toggle-bar {
    background-color: if($is-dark, $gray-6, $gray-6);
  }

  .mat-slide-toggle-content {
    color: if($is-dark, $gray-6, currentColor);
  }

  // If color isn't provided we're assigning gray color to treat it as 'default'
  .mat-slide-toggle.mat-checked:not([color], .mat-disabled) {
    .mat-slide-toggle-thumb {
      background-color: if($is-dark, $gray-5, $gray-5);
    }

    .mat-slide-toggle-bar {
      background-color: if($is-dark, $gray-6, $gray-6);
    }

    .mat-ripple-element {
      background-color: $gray-6;
    }
  }

  .mat-slide-toggle.mat-disabled {
    opacity: 1 !important;

    .mat-slide-toggle-thumb {
      background-color: mat.get-color-from-palette($foreground, disabled);
    }

    .mat-slide-toggle-bar {
      background-color: mat.get-color-from-palette($foreground, disabled);
    }

    .mat-slide-toggle-content {
      color: mat.get-color-from-palette($foreground, disabled);
    }
  }
}

@mixin tweak-slide-toggle-theme($theme) {
  @include tweak-slide-toggle-color($theme);

  .mat-slide-toggle-content {
    font-weight: $fw-semibold;
  }
}

// Radio Button
@mixin tweak-radio-button-color($theme) {
  $color-config: mat.get-color-config($theme);
  $is-dark: map.get($color-config, is-dark);

  .mat-radio-outer-circle {
    border-color: if($is-dark, $gray-5, $gray-4);
  }

  .mat-radio-button {
    color: if($is-dark, $gray-6, $gray-4);
  }
}

@mixin tweak-radio-button-theme($theme) {
  @include tweak-radio-button-color($theme);

  .mat-radio-label {
    font-weight: $fw-normal;
  }
}

// Slider
@mixin tweak-slider-color($theme) {
  $color-config: mat.get-color-config($theme);
  $is-dark: map.get($color-config, is-dark);

  // Only override color when no color is specified
  .mat-slider:not([color], [disabled]) {
    .mat-slider-thumb,
    .mat-slider-track-fill {
      background-color: $gray-5;
    }
  }
}

@mixin tweak-slider-theme($theme) {
  @include tweak-slider-color($theme);

  .mat-slider.mat-slider-disabled .mat-slider-thumb {
    transform: scale(0.7);
  }
}

// Snack bars
@mixin tweak-snack-bar-color($theme) {
  $color-config: mat.get-color-config($theme);
  $is-dark: map.get($color-config, is-dark);

  .mat-snack-bar-container {
    background-color: $gray-3;

    &.snack-bar-is-error {
      background-color: var(--c-error);
    }
  }
}

@mixin tweak-snack-bar-theme($theme) {
  @include tweak-snack-bar-color($theme);

  .mat-snack-bar-container {
    font-weight: $fw-normal;

    &.mat-snack-bar-top {
      margin-top: 80px;
    }
  }
}

// Dialogs
@mixin tweak-dialog-color($theme) {
  $color-config: mat.get-color-config($theme);
  $is-dark: map.get($color-config, is-dark);

  .mat-dialog-header {
    border-bottom: 1px solid if($is-dark, $gray-3, $gray-6);
  }

  .mat-dialog-title {
    color: if($is-dark, $white, $gray-1);
  }
}

@mixin tweak-dialog-theme($theme) {
  @include tweak-dialog-color($theme);

  // This is a custom class, made to simplify styling headers in material dialogs
  .mat-dialog-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: $s-3;
    margin-bottom: $s-4;

    .mat-dialog-title {
      margin-bottom: 0;
    }
  }

  .mat-dialog-container {
    .mat-dialog-actions {
      min-height: 44px;
      margin-bottom: 0;
      margin-top: var(--s-5);
      padding-top: 0;
      padding-bottom: 0;
    }
  }
}

// Tooltips
@mixin tweak-tooltip-theme($theme) {
  .mat-tooltip {
    font-size: $fs-2;
  }
}

// CDK
@mixin tweak-cdk($theme) {
  .cdk-overlay-container {
    /* Set this to be greater than widgets board */
    z-index: 10010;
  }

  html.cdk-global-scrollblock {
    overflow: hidden;
  }
}
